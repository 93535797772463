.ag-theme-DLab {
  --ag-foreground-color: var(--one-color-interaction-default-neutral-4);
  --ag-background-color: var(--one-color-background-base-1);

  --ag-header-foreground-color: var(--one-color-interaction-default-neutral-4);
  --ag-header-background-color: var(--one-color-interaction-default-neutral-1);
  --ag-header-height: 40px;

  --ag-odd-row-background-color: var(--one-color-background-base-1);
  --ag-data-color: var(--one-color-interaction-default-neutral-4);

  --ag-border-color: var(--one-color-interaction-default-neutral-3);
  --ag-font-size: 16px;
  --ag-row-height: 40px;
  --ag-list-item-height: 40px;

  --ag-material-accent-color: var(--one-color-interaction-default-brand-1);

  --ag-control-panel-background-color: var(--one-color-background-base-1);
  --ag-subheader-background-color: var(--one-color-background-base-1);
}
/* .ag-row-group {
  background-color: var(--one-color-background-neutral-2);
} */

.sub-table-grid > .ag-theme-material .ag-layout-auto-height .ag-center-cols-clipper {
  min-height: unset !important;
}
.sub-table-grid-noCount > .ag-theme-material .ag-layout-auto-height .ag-center-cols-clipper {
  min-height: 150px !important;
}

.ag-pinned-right-header {
  border-left: none;
}
.ag-pinned-left-header {
  border-right: none;
}
.ag-body-horizontal-scroll:not(.ag-scrollbar-invisible) .ag-horizontal-left-spacer:not(.ag-scroller-corner) {
  border-right: none !important;
}

/* To hide border in pinned cluster column  */
.ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
  border-right: 1px solid transparent;
}

/* To hide border in pinned action column  */
.ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
  border-left: 1px solid transparent;
}
.ag-header-row {
  height: 40px !important;
  min-height: 40px !important;
}
.ag-header-cell-label {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.ag-side-buttons {
  width: 46px;
  border: 1px solid var(--one-color-interaction-default-neutral-2);
}
.ag-tool-panel-wrapper {
  border: 1px solid var(--one-color-interaction-default-neutral-2);
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 40%) 0px 0px 3px;
}
.ag-theme-material .ag-column-select,
.ag-theme-material .ag-column-select-header {
  border-bottom: 1px solid var(--one-color-interaction-default-neutral-2);
}
.ag-theme-material .ag-column-select,
.ag-theme-material .ag-column-select-header {
  border: none;
}
.sub-components-row .ag-row-level-1 {
  border-bottom: 1px solid var(--one-color-interaction-default-neutral-2) !important;
}

.ag-theme-material .ag-column-panel-column-select {
  border: none;
}
.ag-column-select-virtual-list-item {
  border-bottom: 1px solid var(--one-color-interaction-default-neutral-2);
}
.ag-filter-toolpanel-group-wrapper {
  border-bottom: 1px solid var(--one-color-interaction-default-neutral-2);
}
.ag-filter-virtual-list-item {
  background-color: var(--one-color-background-base-1);
  border-bottom: 1px solid var(--one-color-interaction-default-neutral-2);
}

.cluster-icon {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-right: 0px !important;
  padding-left: 5px !important;
}

.cluster-icon .ag-cell-wrapper .ag-group-value {
  padding-top: 10px !important;
}

.action-render {
  padding: 0px !important;
}

.action-render .ag-cell-wrapper {
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.action-render .action-icon {
  color: #8d8d8d;
  font-size: 1.25rem;
}
.ag-overlay-wrapper .ag-react-container {
  flex: 0 0 99%;
}
.ag-filter-apply-panel-button {
  background-color: var(--one-color-background-brand-1) !important;
  padding: 6px 16px !important;
  font-size: 0.875rem !important ;
  text-transform: none !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  color: #fff !important;
  border: 1px solid rgba(0, 102, 204, 0.5) !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}

.ag-overlay-wrapper .ag-react-container .myLabNoDataOverLay {
  background-color: var(--one-color-background-base-1);
  border: 1px dashed var(--one-color-interaction-default-brand-1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 210px;
}
.ag-overlay-wrapper .ag-react-container .myLabNoDataOverLaywithoutEditMode {
  background-color: var(--one-color-background-base-1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 210px;
}
.ag-side-button.ag-selected .ag-side-button-button .ag-icon,
.ag-side-button.ag-selected .ag-side-button-button span,
.ag-side-button.ag-selected .ag-side-button-button .ag-side-button-label,
.ag-side-button.ag-selected .ag-side-button-button .ag-side-button-icon-wrapper,
.ag-side-button.ag-selected .ag-side-button-button .ag-side-button-icon-wrapper .icon-type-legacy,
.ag-side-button.ag-selected .ag-side-button-button .ag-side-button-icon-wrapper svg path {
  color: var(--one-color-interaction-active-brand-1);
  stroke: var(--one-color-interaction-active-brand-1);
}
.ag-side-button-button .ag-icon-filter {
  transform: rotate(90deg);
}
