.BookingEquipmentListTabWidth {
  width: 100%;
  display: flex;
  transition: all 0.5s ease 0s;
  border-radius: 0.25rem;
  overflow: hidden;
  box-shadow: var(--one-elevation-box-shadow-2);
}
.BookingEquipmentListTabWidth button {
  background-color: var(--one-color-interaction-default-base-1);
  color: var(--one-color-interaction-default-neutral-4);
  border: none;
  min-height: 3rem;
  outline: none;
  overflow: hidden;
  font-family: var(--one-text-font-family-default);
  font-size: var(--one-text-font-size-rem-1);
  font-weight: var(--one-text-font-weight-500);
  transition: all 0.5s ease 0s;
  margin: 0px;
  cursor: pointer;
  box-shadow: none;
  width: 232px;
}

.BookingEquipmentListTabWidth button:nth-child(2) {
  width: 400px;
  border-right: 1px solid var(--one-color-background-contrast-1); /* Prevent double borders */
  border-left: 1px solid var(--one-color-background-contrast-1); /* Prevent double borders */
}
/* Add a background color on hover */
.BookingEquipmentListTabWidth button:hover {
  background-color: var(--one-color-background-brand-2);
  color: var(--one-color-gray-800);
}
.activeButton {
  background-color: var(--one-color-interaction-active-brand-4);
  color: var(--one-color-interaction-focus-brand-1);
}
.BookingEquipmentListTabWidth button:active {
  background-color: var(--one-color-interaction-active-brand-4);
  color: var(--one-color-interaction-focus-brand-1);
}
.BookingEquipmentListTabWidth button:focus {
  background-color: var(--one-color-interaction-active-brand-4);
  color: var(--one-color-interaction-focus-brand-1);
}
.BookingEquipmentListTabWidth button:disabled {
  background: var(--one-color-interaction-disabled-base-1);
  color: var(--one-color-interaction-disabled-neutral-3);
}
.BookingEquipmentListTabWidth button > p {
  font-family: var(--one-typography-font-family);
  font-size: var(--one-typography-desktop-subtitle-1-font-size);
  margin: 5px;
}
