/* Style for user pop over */
.popover,
.popoverMenuList {
  color: var(--one-color-foreground-base-1);
  background-color: var(--one-color-background-base-1);
}
.UserInfoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
}
.LogoLetter {
  background: var(--one-color-interaction-default-brand-1);
  color: var(--one-color-interaction-default-base-1);
  font-size: 24px;
  padding: 16px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-family: var(--one-text-font-family-default);
}
.VerticalLine {
  margin-left: 12px;
  width: 1px;
  height: 20px;
  background: var(--one-color-cobas-gray-600);
}
.UserLogo {
  // @extend .typography-variant-subtitle1;
  border-radius: 100%;
  background-color: var(--one-color-petrol-500);
  font-size: var(--one-text-font-size-px-20);
  font-family: var(--one-text-font-family-default);
  font-weight: var(--one-text-font-weight-400);
  color: var(--one-color-foreground-neutral-2);
  letter-spacing: 0px;
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 12px 0;
}
.UserName {
  font-size: var(--one-text-font-size-px-16);
  font-weight: var(--one-text-font-weight-400);
  line-height: 16px;
  letter-spacing: 0px;
  margin-bottom: 4px;
  margin-bottom: 4px;
  text-transform: capitalize;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.UserEmail {
  width: 200px;
  font-size: var(--one-text-font-size-px-14);
  font-weight: var(--one-text-font-weight-400);
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.AppBarInfoUserInfo {
  margin-right: 25px;
  display: flex;
  align-items: center;
}

.AdminPanelRightAlignMenu {
  margin-left: auto;
}
.disabledTab,
.disabledTab > span {
  cursor: unset;
  color: var(--one-color-interaction-disabled-neutral-2);
}
.disabledTab:link,
.disabledTab:hover {
  background-color: unset;
}
.disabledTab:visited,
.disabledTab:active {
  background-color: unset;
  border: unset;
}
.disabledTab {
  pointer-events: none;
}
.appbarDoubleGlobalAreaWithoutEnv {
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.4));
  position: fixed;
  top: 0;
  z-index: 0;
}
.appbarDoubleGlobalAreaWithEnv {
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.4));
  position: fixed;
  top: 49px;
  z-index: 0;
}
.appbarPrimary {
  justify-content: space-between;
  border-bottom: none;
  filter: none;
}
.appbarPrimaryDiv {
  padding-left: 16px;
  display: flex;
  align-items: center;
}
.appbarPopoverWidth {
  width: 340px;
}
.appbarLogoutContainer {
  display: flex;
  justify-content: right;
  margin: 16px;
}
