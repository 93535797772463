.welcomeScreenWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(90vh - 96px);
  color: var(--one-color-background-brand-1);

  .wrapTitle5 {
    height: 200px;
    color: var(--one-color-foreground-base-1);
  }
}
