.scanButton {
  color: var(--one-color-foreground-brand-1) !important;
}
.scanButtonContainer {
  display: flex;
  cursor: pointer;
}
.iconSpace {
  margin-right: 8px;
}
