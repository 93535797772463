.flexContainerContent {
  height: 550px;
}

.flexContainer {
  display: flex;
  padding: 10px 0px 15px 20px;
}

.flexLeftColumn {
  width: 45%;
  border-radius: 4px;
  border: 1px solid var(--one-color-cobas-gray-400);
  margin-right: 15px;
}

.flexRightColumn {
  width: 55%;
  border-radius: 4px;
  margin-right: 20px;
  border: 1px solid var(--one-color-cobas-gray-400);
}

.typographyTitle {
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.lineTitle {
  border-bottom: 1px solid var(--one-color-cobas-gray-400);
  color: #121212;
}

.scrollBarlist {
  height: 422.8px;
  padding-left: 1.2px;
  overflow-x: hidden;
  overflow-y: auto;
}

.bookTypeFilled {
  padding: 10px 20px 10px 20px;
}

.bookForFilled {
  padding: 10px 20px 10px 20px;
}

.textFilled {
  padding: 10px 20px 10px 20px;
}

.renderTextField {
  padding-left: 5px;
}
