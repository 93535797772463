@import "~@one/web-components/dist/owc/owc.css";
@import "~@one/icons/dist/one-icons-legacy.css";
@import "~@one/icons/dist/one-icons-filled.css";
@import "~@one/icons/dist/one-icons-outlined.css";
@import "~@one/roche-font/roche-font.css";
// @import "./calendar.scss";
@import "@mobiscroll/react/dist/css/mobiscroll.min.css";

body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  font-family: var(--one-typography-font-family);
  background-color: var(--one-color-background-brand-2) !important;
  color: var(--one-color-foreground-base-1);
}
.date-picker-my-booking .mbsc-material.mbsc-textfield-box-floating,
.mbsc-material.mbsc-textfield-box-stacked {
  height: 3em !important;
}

.date-picker-my-booking > .mbsc-material.mbsc-textfield-box.mbsc-select,
.mbsc-material.mbsc-textfield-inner-box {
  width: 250px !important;
}

.MuiAppBar-positionAbsolute {
  position: relative !important;
}
.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-elevation0 {
  position: relative;
}
#drawerToolBar {
  margin-top: 0px !important;
}
.md-custom-event-cont {
  font-size: 12px;
  font-weight: 600;
  height: 100%;
  background: #fff;
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 0 10px 10px 0;
  overflow: hidden;
}

.md-custom-event-wrapper {
  position: relative;
  background: rgba(255, 255, 255, 0.5);
  height: 100%;
  min-height: 135px;
  box-sizing: border-box;
  border-radius: 0 10px 10px 0;
  transition: background 0.15s ease-in-out;
}

.mbsc-schedule-event-hover .md-custom-event-cont .md-custom-event-wrapper {
  background: rgba(255, 255, 255, 0.3);
}

.mbsc-schedule-event-active .md-custom-event-cont .md-custom-event-wrapper {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 1px 3px -1px rgba(0, 0, 0, 0.2);
}

.mbsc-schedule-event-hover .md-custom-event-allday-title {
  opacity: 0.8;
}

.mbsc-schedule-event-active .md-custom-event-allday-title {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 1px 3px -1px rgba(0, 0, 0, 0.2);
}

.md-custom-event-category {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  border-radius: 10px;
  padding: 10px 5px;
  text-wrap: wrap;
}

.md-custom-event-details {
  padding: 0 10px;
}

.md-custom-event-title {
  color: initial;
}

.md-custom-event-time {
  font-size: 10px;
  color: #666;
  padding-top: 3px;
}

.md-custom-event-description {
  font-size: 12px;
  padding-top: 3px;
}

.md-custom-event-allday-title {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  padding: 2px 10px;
  border-radius: 10px;
  opacity: 0.7;
}

.md-custom-event-btn.mbsc-button {
  margin: 5px 0;
  line-height: 20px;
  padding-top: 0;
  padding-bottom: 0;
}

.md-cutom-event-img-cont {
  position: absolute;
  bottom: 5px;
  left: 10px;
  white-space: nowrap;
}

.md-custom-event-img {
  width: 25px;
  height: 25px;
  margin: 0 2px;
}

.d-flex {
  display: flex;
  justify-content: space-between;
  .right_flex {
    align-self: center;
  }
}
