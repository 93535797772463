code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.owcmodalZIndex {
  position: relative;
  z-index: 10001;
}
.owcListItemBorder {
  border-bottom: 1px solid var(--one-color-interaction-default-neutral-2);
}

*::-webkit-scrollbar {
  width: 0.75rem;
  /* background: #fafafa; */
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #bababa;
  border-radius: 0.375rem;
  border: 3px solid #fafafa;
}
.MuiPopover-root {
  z-index: 100001 !important;
}
