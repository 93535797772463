/* Hour styles */
.mbsc-timeline-footer-time,
.mbsc-timeline-header-time {
  padding: 0px;
}
.md-date-header-hour {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #000;
}

.md-date-header-events-available {
  background: #d7e0f7;
}

.md-timeline-template .mbsc-schedule-event.mbsc-ltr {
  height: auto !important;
}

.md-timeline-template-event {
  border: 1px solid transparent;
  margin: 2px 0;
}

.md-timeline-template-event-cont {
  font-size: 15px;
  height: 32px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.md-timeline-template-event-cont .bookingIcon {
  box-sizing: content-box;
  padding: 8px 8px;
  border-radius: 31px;
}

.mbsc-timeline-event-start .md-timeline-template-event,
.mbsc-timeline-event-start .md-timeline-template-event-cont,
.mbsc-timeline-event-start .md-timeline-template-event-cont .mbsc-icon {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.mbsc-timeline-event-end .md-timeline-template-event,
.mbsc-timeline-event-end .md-timeline-template-event-cont,
.mbsc-timeline-event-end .md-timeline-template-event-cont .mbsc-icon {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.md-timeline-template-event-cont .mbsc-icon:before {
  color: #fff;
  font-size: 18px;
}

.md-timeline-template-time {
  margin: 0 10px;
}

.md-timeline-template-title {
  color: #666;
}

.md-timeline-template .mbsc-timeline-column,
.md-timeline-template .mbsc-timeline-header-column {
  min-width: 50px;
}

.md-timeline-template .mbsc-timeline-resource,
.md-timeline-template .mbsc-timeline-row {
  min-height: 50px;
}
.mbsc-material.mbsc-textfield-wrapper {
  margin: 0;
}
/*textfield style*/
.mbsc-material.mbsc-textfield-box.mbsc-select,
.mbsc-material.mbsc-textfield-inner-box {
  width: 400px;
  background-color: var(--one-color-interaction-active-brand-4);
}

.customeventcalendar_datepickerInputStyle .mbsc-material.mbsc-textfield-outline {
  height: unset;
  width: 400px;
  background-color: var(--one-color-interaction-active-brand-4);
  border: none;
}
.customeventcalendar_datepickerInputStyle .mbsc-material.mbsc-textfield-icon-floating,
.customeventcalendar_datepickerInputStyle .mbsc-material.mbsc-textfield-icon-outline,
.customeventcalendar_datepickerInputStyle .mbsc-material.mbsc-textfield-icon-stacked {
  top: 0px;
}

.mbsc-material.mbsc-textfield-inner-outline {
  padding: 0;
  border: 2px solid var(----one-color-background-brand-1);
}
.mbsc-material.mbsc-button,
.mbsc-material.mbsc-range-control-label {
  text-transform: unset;
}

//time alignment in the event calendar
.md-date-header-hour {
  text-align: left;
}

//grid border color
.mbsc-ios.mbsc-schedule-all-day-item:after,
.mbsc-ios.mbsc-schedule-column,
.mbsc-ios.mbsc-schedule-item,
.mbsc-ios.mbsc-timeline-column,
.mbsc-ios.mbsc-timeline-day:after,
.mbsc-ios.mbsc-timeline-resource,
.mbsc-ios.mbsc-timeline-resource-footer-cont,
.mbsc-ios.mbsc-timeline-resource-header-cont,
.mbsc-ios.mbsc-timeline-row,
.mbsc-ios.mbsc-timeline-row-date,
.mbsc-ios.mbsc-timeline-row-group:after,
.mbsc-ios.mbsc-timeline-sidebar-footer-cont,
.mbsc-ios.mbsc-timeline-sidebar-header-cont,
.mbsc-ios.mbsc-timeline-sidebar-resource,
.mbsc-ios.mbsc-timeline-slot-header,
.mbsc-ios.mbsc-timeline-slots {
  border-color: var(--one-color-cobas-gray-100);
}

//grid border style
.mbsc-schedule-all-day-item.mbsc-ltr:after,
.mbsc-timeline-day-border.mbsc-ltr:after {
  border-right-style: none;
}

//hours padding
.md-date-header-hour {
  padding: 0 4px;
}

.custom-label-text > * .mbsc-calendar-label-text {
  font-size: 8px;
}
