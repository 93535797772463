.customEventStatus {
  display: flex;
}

.customEventIconText {
  display: flex;
  margin-right: 50px;
  align-items: center;
}

.customEventStatusIcon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
