.booleanButton {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid var(--one-color-background-contrast-1);
  border-radius: 30px;
}

.booleanButton button {
  background-color: unset;
  border: none;
  padding: 5px 10px;
}
.booleanButton button:nth-child(1) {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.booleanButton button:nth-child(3) {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
/* Add a background color on hover */
.booleanButton button:hover {
  background-color: var(--one-color-background-brand-2);
  color: var(--one-color-gray-800);
}
.booleanButton button:active {
  color: var(--one-color-interaction-focus-brand-1);
}
.booleanButton button:focus {
  background-color: var(--one-color-interaction-active-brand-4);
  color: var(--one-color-interaction-focus-brand-1);
}
.booleanButton > .verticalLine {
  min-height: 38px;
  border: 1px solid var(--one-color-background-contrast-1);
}
